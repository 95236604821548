.App {
  text-align: center;
}

body {
  background-color: #eeeeee;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

h1 {
  padding-left: 200px; 
  padding-right: 200px;
  margin-bottom: 0; 
}

h2 {
  margin: 0; 
}

.container {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px; 
}

.stats {
  display: flex;
  width: 100vw;
  align-items: center; 
  justify-content: center;
}

.stat {
  width: 400px;
  height: 150px;
  background-color: white;
  padding: 30px;
  /* margin-right: 30px;  */
  margin-top: 50px;
  margin-right: 30px; 
  display: flex;
  align-items: space-between;
  flex-direction: column;
}


p {
  margin-top: 20px;
  font-size: 50px;
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0; 
}

span {
  font-size: 12px; 
  padding: 10px; 
}

@media only screen and (max-width: 700px) {
  h1 {
      padding-left: 30px;
      padding-right: 30px;
      margin-bottom: 0;
    }

  .stats {
    flex-direction: column;
  }

  .stat {
    width: 200px;
    height: 100px;
    padding: 30px;
    /* margin-right: 30px;  */
    margin-top: 30px;
    margin-right: 0; 
    display: flex;
    align-items: space-between;
    flex-direction: column;
  }
}